import { default as React } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { FooterLayout, Layout } from "@layouts";


const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query PaymentSuccessQuery {
      footer: layout(layoutMetadata: {scope: {eq: FOOTER}}) {
        ...Footer
      }

      header: layout(layoutMetadata: {scope: {eq: HEADER}}) {
        ...Header
      }

      page: page(
        pageMetadata: {scope: {eq: "payment-success"}}
      ) {
        ...Page
      }

      router: layout(layoutMetadata: {scope: {eq: ROUTER}}) {
        ...Router
      }

      site: site {
        ...Site
      }
    }
  `);

  return (
    <Layout
      title="Payment Success"
      className="white-version"
    >
        <main className="main-page-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <ul class="nav">
                  <li class="nav-item">
                    <a href="/" class="nav-link" style={{color: "#3c3e41"}}>Home</a>
                  </li>
                </ul>

                <p>
                  <StaticImage
                    alt="Success"
                    src="../assets/images/check.svg"
                    width={144}
                  />
                </p>

                <p><strong>Thanks for your payment</strong></p>

                <p>A payment to DAVID-FLUSBERG.COM will appear on your statement</p>
              </div>
            </div>
          </div>
        </main>
      {(data.footer && data.footer) && (
        <FooterLayout
          data={{
            header: data.header,
            footer: data.footer
          }}
        />
      )}
    </Layout>
  );
}


export default NotFoundPage;
